import React from "react";
import { ReactComponent as IconFacebook } from "./assets/icons/facebook.svg";
import { ReactComponent as IconInstagram } from "./assets/icons/instagram.svg";
import { ReactComponent as Logo } from "./assets/images/logo.svg";
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <a href=".">
              <Logo className="logo image" />
            </a>
          </div>
          <div className="social">
            <a
              href="https://www.facebook.com/profile.php?id=61560814025729"
              title="Facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconFacebook className="icon" />
            </a>
            <a
              href="https://www.instagram.com/adverti.official/"
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconInstagram className="icon" />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Prepare yourself for the upcoming transformation.</h1>
            <p>
              Our website is under construction. Please check back soon for
              updates. Feel free to send us an email if you have any questions
              or inquiries.
            </p>
          </div>
          <a href="mailto:advertI.ads1@gmail.com">
            <div className="cta">Send us an email</div>
          </a>
        </div>
      </div>
    );
  };
}

export default App;
